import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import cx from 'classnames'

const JobPost = ({ location, title, url }) => {
    return (
        <li className={cx('leading-relaxed mb-24')}>
            <a
                href={url}
                className={cx('text-blue-500 font-medium hover:text-blue-700')}
                data-track-gtm={`Career Links|${title}|${url}`}
            >
                {title}
            </a>
            {!!location.length && <p>{location[0]}</p>}
        </li>
    )
}

JobPost.propTypes = {
    location: PropTypes.array,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
}

const CareersList = () => {
    const username = process.env.GREENHOUSE_API_TOKEN
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const getJobs = async () => {
            const { data } = await axios.get(
                'https://api.lever.co/v0/postings/beacon?group=team&mode=json'
            )

            setPosts(data)
        }

        getJobs()
    }, [username])

    return (
        <div>
            {!!posts.length && (
                <div key="all">
                    <ul className={cx('-mb-24')}>
                        {posts.map(
                            ({ postings }) =>
                                !!postings.length &&
                                postings.map(
                                    ({ categories, id, text, hostedUrl }) => {
                                        return (
                                            <JobPost
                                                key={id}
                                                location={
                                                    categories.allLocations
                                                }
                                                title={text}
                                                url={hostedUrl}
                                            />
                                        )
                                    }
                                )
                        )}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default CareersList
