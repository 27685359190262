import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IntroText from '../components/IntroText'
import CareersList from '../components/CareersList'
import Content, { HTMLContent } from '../components/Content'
import Image from '../components/Image'
import PageBackgroundImage from '../components/PageBackgroundImage'

export const CareersPageTemplate = ({
    title,
    subHeading,
    content,
    contentComponent,
    backgroundImage,
    featuredImage,
    cultureImage,
}) => {
    const PageContent = contentComponent || Content

    return (
        <section
            className={cx('max-w-1264 mx-auto px-24 lg:px-32 py-60 lg:pb-120')}
        >
            {backgroundImage && <PageBackgroundImage image={backgroundImage} />}

            <div className={cx('md:flex md:justify-between')}>
                <div className={cx('md-d:mb-24 md:w-2/5')}>
                    <h1 className={cx('hd-jb text-purple-700 mb-32')}>
                        {title}
                    </h1>

                    {subHeading && <IntroText>{subHeading}</IntroText>}

                    <PageContent
                        className={cx('rich-text')}
                        content={content}
                    />
                </div>
                <div className={cx('md:w-1/2')}>
                    {featuredImage && (
                        <Image
                            image={featuredImage}
                            alt=""
                            className={cx('mb-40')}
                        />
                    )}

                    <div className={cx('bg-gray-100 p-24 lg:p-60')}>
                        <h2 className={cx('hd-md mb-32')}>Current Openings</h2>
                        <CareersList />
                    </div>
                </div>
            </div>

            {cultureImage && (
                <Image
                    image={cultureImage}
                    alt=""
                    className={cx('w-full mt-90')}
                />
            )}
        </section>
    )
}

CareersPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    subHeading: PropTypes.string,
    backgroundImage: PropTypes.object,
    featuredImage: PropTypes.object,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
    cultureImage: PropTypes.object,
}

const CareersPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout
            title={post.frontmatter.title}
            description={post.frontmatter.subHeading}
        >
            <CareersPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                subHeading={post.frontmatter.subHeading}
                backgroundImage={post.frontmatter.backgroundImage}
                featuredImage={post.frontmatter.featuredImage}
                cultureImage={post.frontmatter.cultureImage}
                content={post.html}
            />
        </Layout>
    )
}

CareersPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default CareersPage

export const careersPageQuery = graphql`
    query CareersPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                subHeading
                backgroundImage {
                    extension
                    publicURL
                }
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 600, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                cultureImage {
                    childImageSharp {
                        fluid(maxWidth: 1264, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`
